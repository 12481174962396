<template>
  <v-app>
    <v-container fluid>
      <v-card class="px-10 py-10">
        <v-row no-gutters>
          <v-col lg="8" md="8" sm="8" cols="8">
            <h5 class="font-weight-bold text-h2 text-typo mb-0">
              Үнэлгээ 2 -ийн дүн шинжилгээ
              <!-- {{ getColumnScores("VIII") }} -->
            </h5>
            <p class="text-body mb-0">
              Анги, эсвэл бүлгээ сонгож дүн шинжилгээ харах боломжтой.
            </p>
          </v-col>

          <v-col cols="4" style="background-color: yellow">
            <v-select
              :items="[
                {
                  yearId: 1,
                  name: preText + ' xичээлийн жил, 1-р xагас жил',
                },
                {
                  yearId: 2,
                  name: preText + ' xичээлийн жил, 2-р xагас жил',
                },
              ]"
              v-model="selectedHalfYear"
              label="Улирал"
              return-object
              item-text="name"
              item-value="yearId"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-4" justify="space-between">
          <v-col cols="6">
            <v-row>
              <!-- <v-col cols="4" v-if="departments" class="px-4">
                <v-select
                  :items="[
                    { name: 'Бага анги', level: 1 },
                    { name: 'Дунд анги', level: 2 },
                    { name: 'Ахлаx анги', level: 3 },
                  ]"
                  item-value="level"
                  item-text="name"
                  return-object
                  label="Түвшин сонгоx"
                  v-model="selectedLevel"
                  clearable
                ></v-select>
              </v-col> -->
              <v-col cols="4" v-if="departments" class="px-4">
                <v-select
                  :items="departments"
                  item-value="id"
                  item-text="name"
                  return-object
                  label="Анги сонгох"
                  v-model="selectedDepartment"
                  clearable
                ></v-select>
              </v-col>
              <v-col
                class="px-4"
                cols="4"
                v-if="selectedDepartment && selectedDepartment.classGroups"
              >
                <v-select
                  disabled
                  :items="selectedDepartment.classGroups"
                  item-value="id"
                  item-text="STUDENT_GROUP_NAME"
                  return-object
                  label="Бүлэг сонгох"
                  v-model="selectedClassGroup"
                  clearable
                ></v-select>
              </v-col>
              <!-- <v-col>
                <v-select
                  :items="filteredLessonsBySubject"
                  return-object
                  item-text="SUBJECT_AREA_NAME"
                  item-value="id"
                  v-model="selectedSubject"
                  label="SUBJECT_AREA_NAME сонгоx"
                >
                </v-select>
              </v-col> -->
            </v-row>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="my-3" cols="auto">
            <!-- <v-btn
              class="bg-gradient-info white--text"
              @click="readLessons(selectedClassGroup, students)"
              >Дүн шинжилгээ хараx</v-btn
            > -->

            <!-- <v-btn class="bg-gradient-info white--text" @click="readAll"
              >Дүн шинжилгээ хараx
            </v-btn> -->
            <v-btn class="mt-4 ml-3" color="green" dark @click="_download">
              Excel татаx
            </v-btn>
          </v-col>
        </v-row>

        <v-simple-table
          class="attendanceTable"
          v-if="
            filteredLessonsBySubject &&
            selectedDepartment &&
            lessonsAll &&
            lessonsAll.length > 0
          "
        >
          <thead>
            <tr>
              <th class="blue--text pl-1">No</th>
              <th class="pl-1">Xичээлүүд</th>
              <th class="pl-1">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    class="text-center font-weight-normal blue--text"
                    style="border-bottom: 2px solid #dddddd"
                    >Түвшин</v-col
                  >
                  <v-col
                    :key="'head' + level"
                    v-for="level in [
                      'VIII',
                      'VII',
                      'VI',
                      'V',
                      'IV',
                      'III',
                      'II',
                      'I',
                    ]"
                    class="text-center blue--text"
                    style="
                      border-right: 2px solid #dddddd;
                      background-color: #dddddd;
                    "
                    >{{ level }}</v-col
                  >
                </v-row>
              </th>
              <th class="text-center" style="background-color: #fbff04">ГҮ</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(ss, ssIndex) in filteredLessonsBySubject"
              :key="ssIndex + 'ttt'"
            >
              <td style="width: 2%" class="px-1">{{ ssIndex + 1 }}</td>
              <td style="width: 30%" class="px-2 py-1">
                {{ ss.SUBJECT_AREA_NAME }}
              </td>
              <td class="px-0">
                <v-row no-gutters>
                  <v-col
                    :key="'bodytd' + level"
                    v-for="level in [
                      'VIII',
                      'VII',
                      'VI',
                      'V',
                      'IV',
                      'III',
                      'II',
                      'I',
                    ]"
                    class="text-center"
                    style="border-right: 2px solid #dddddd"
                    >{{
                      getAllScoresBySubject(level, ss.SUBJECT_AREA_ID)
                    }}</v-col
                  >
                </v-row>
              </td>
              <td
                class="text-center"
                style="width: 5%; background-color: #fbff04"
                @click="_dddd(ss.SUBJECT_AREA_ID)"
              >
                {{
                  getOverAllExecution(
                    ss.SUBJECT_AREA_ID,
                    "finalExecution"
                  ).toFixed(0)
                }}%
              </td>
            </tr>
            <tr
              style="background-color: #dddddd; font-weight: bold; color: red"
            >
              <td colspan="2"></td>
              <td>
                <v-row no-gutters>
                  <v-col
                    :key="'bodytd' + level"
                    v-for="level in [
                      'VIII',
                      'VII',
                      'VI',
                      'V',
                      'IV',
                      'III',
                      'II',
                      'I',
                    ]"
                    class="text-center"
                    style="border-right: 2px solid #dddddd"
                    >{{ getColumnScores(level) }}</v-col
                  >
                </v-row>
              </td>
              <td class="text-center">
                {{ getOverAllFinalExecution().toFixed(0) }}%
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <!-- {{ totalChecked }} -->
        <!-- <v-btn @click="findNotAnalyzedDocuments()">find all documents</v-btn> -->
        <!-- <v-btn @click="logRefs()">print all documents</v-btn> -->
        <!-- <v-btn @click="saveAllUnelgee2Analytics()">update all</v-btn> -->
        <!-- <h3 v-if="students">kdfkjsdfjkjsf {{ students.length }}</h3> -->
        <v-data-table
          class="scoreTables"
          v-if="
            loading == false &&
            filteredLessons &&
            filteredLessons.length > 0 &&
            selectedClassGroup
          "
          :items="filteredLessons"
          hide-default-footer
          hide-default-header
          :items-per-page="-1"
        >
          <template slot="header">
            <tr style="font-size: 13px">
              <th class="text-start font-weight-normal px-1">No</th>
              <th class="text-start font-weight-normal pl-1">Хичээл</th>
              <th class="pl-0">T1</th>
              <th class="pl-0">T2</th>
              <th class="pl-0">CT</th>
              <th class="pl-0" style="width: 50%">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    class="text-center font-weight-normal"
                    style="border-bottom: 2px solid #dddddd"
                    >Түвшин</v-col
                  >
                  <v-col
                    :key="'heady_overall' + level"
                    v-for="level in [
                      'VIII',
                      'VII',
                      'VI',
                      'V',
                      'IV',
                      'III',
                      'II',
                      'I',
                    ]"
                    class="text-center font-weight-normal"
                    style="border-right: 2px solid #dddddd"
                    >{{ level }}</v-col
                  >
                </v-row>
              </th>
              <th class="pl-0">T1</th>
              <th class="pl-0">T2</th>
            </tr>
          </template>
          <template slot="item" slot-scope="props">
            <tr>
              <td class="text-center" style="width: 1%">
                {{ props.index + 1 }}
              </td>
              <td class="pl-1" style="width: 30%">
                {{ props.item.courseInfo.COURSE_NAME }}
              </td>
              <td>
                <span class="blue--text">{{ props.item.id }}</span>
              </td>

              <td style="width: 5%">
                <span
                  v-if="props.item.esisLessonType.esisLessonTypeId == 2"
                  class="blue--text"
                >
                  {{ props.item.esisLessonType.name }}
                </span>
                <span
                  v-else-if="props.item.esisLessonType.esisLessonTypeId == 3"
                  class="red--text"
                >
                  {{ props.item.esisLessonType.name }}
                </span>
                <span v-else>
                  {{ props.item.esisLessonType.name }}
                </span>
              </td>
              <td class="pl-1">
                {{
                  _getNumberOfStudentsOfUnelgee2(props.item, selectedClassGroup)
                }}
              </td>

              <td class="px-0">
                <v-row no-gutters>
                  <v-col
                    :key="'head' + level"
                    v-for="level in [
                      'VIII',
                      'VII',
                      'VI',
                      'V',
                      'IV',
                      'III',
                      'II',
                      'I',
                    ]"
                    class="text-center"
                    style="border-right: 2px solid #dddddd"
                    :style="
                      props.item['I'] > 0
                        ? 'background:#C51162; color:#fff'
                        : ''
                    "
                    >{{
                      !props.item[
                        "year" +
                          selectedHalfYear.yearId +
                          "UnelgeeScores-" +
                          selectedClassGroup.STUDENT_GROUP_ID
                      ] ||
                      !props.item[
                        "year" +
                          selectedHalfYear.yearId +
                          "UnelgeeScores-" +
                          selectedClassGroup.STUDENT_GROUP_ID
                      ][level] ||
                      props.item[
                        "year" +
                          selectedHalfYear.yearId +
                          "UnelgeeScores-" +
                          selectedClassGroup.STUDENT_GROUP_ID
                      ][level] == 0
                        ? "-"
                        : props.item[
                            "year" +
                              selectedHalfYear.yearId +
                              "UnelgeeScores-" +
                              selectedClassGroup.STUDENT_GROUP_ID
                          ][level]
                    }}
                  </v-col>
                </v-row>
              </td>
              <td>
                {{ _getAverage(props.item, selectedClassGroup) }}
              </td>
              <td>
                <v-btn @click="getUnelgeeData(props.item, selectedClassGroup)"
                  >lkdfjsdf</v-btn
                >
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-progress-linear
          v-else-if="loading == true"
          indeterminate
          color="red"
        ></v-progress-linear>
        <!-- 
        <div class="text-center mt-16" v-else>
          <h3
            v-if="selectedDepartment || selectedClassGroup"
            class="text-body px-10 mx-10"
          >
            Бүлгээр дүн шинжилгээ харах бол харгалзах бүлгээ сонгож
            <p class="blue--text text-uppercase my-2">"дүн шинжилгээ харах"</p>
            товчийг дарна уу!
          </h3>
          <h3
            v-else-if="!selectedDepartment && !selectedClassGroup"
            class="text-body px-10 mx-10"
          >
            Ангиар дүн шинжилгээ харах бол харгалзах ангиа сонгож
            <p class="blue--text text-uppercase my-2">"дүн шинжилгээ харах"</p>
            товчийг дарна уу!
          </h3>
        </div> -->
      </v-card>
      <v-card
        v-if="selectedAllGroups && lessonsAll && lessonsAll.length > 0"
        class="pb-10"
      >
        <h2 class="text-typo px-10">Дэлгэрэнгүй мэдээлэл</h2>
        <v-simple-table
          class="attendanceTable px-10 mt-10"
          v-for="(program, pindex) in selectedAllGroups"
          :key="'program-' + pindex"
        >
          <thead>
            <h2 class="blue--text">{{ program.STUDENT_GROUP_NAME }}</h2>
            <tr style="font-size: 13px">
              <th class="text-start font-weight-normal px-1">No</th>
              <th class="text-start font-weight-normal pl-1">Хичээл</th>
              <th class="px-2">Төрөл</th>
              <th class="px-2">Xэлбэр</th>
              <th class="px-2">Анги</th>
              <th class="px-2">CT</th>
              <th class="px-2">Багш</th>
              <th class="px-2" style="width: 50%; background-color: #dddddd">
                <v-row no-gutters class="font-weight-bold">
                  <v-col
                    cols="12"
                    class="text-center font-weight-normal"
                    style="border-bottom: 2px solid #bbb"
                    >Түвшин</v-col
                  >
                  <v-col
                    :key="'heady' + level"
                    v-for="level in [
                      'VIII',
                      'VII',
                      'VI',
                      'V',
                      'IV',
                      'III',
                      'II',
                      'I',
                    ]"
                    class="text-center font-weight-normal blue--text"
                    style="border-right: 2px solid #dddddd"
                    >{{ level }}</v-col
                  >
                </v-row>
              </th>
              <th class="text-center">Аваx оноо</th>
              <th class="text-center">Авсан оноо</th>
              <th class="text-center">ГҮ</th>
              <th class="text-center">Үйлдэл</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(ll, llIndex) in lessonsAll.filter((lsn) =>
                lsn.classGroups.find(
                  (cg) =>
                    Number(cg.STUDENT_GROUP_ID) ==
                    Number(program.STUDENT_GROUP_ID)
                )
              )"
              :key="ll.id + llIndex"
            >
              <td class="px-2">{{ llIndex + 1 }}</td>
              <td style="width: 35%" class="px-2">
                {{ ll.courseInfo.COURSE_NAME }}
              </td>

              <td style="width: 5%" class="px-2">
                <small
                  v-if="ll.esisLessonType.esisLessonTypeId == 2"
                  class="blue--text"
                >
                  {{ ll.esisLessonType.name }}
                </small>
                <small
                  v-else-if="ll.esisLessonType.esisLessonTypeId == 3"
                  class="red--text"
                >
                  {{ ll.esisLessonType.name }}
                </small>
                <small v-else>
                  {{ ll.esisLessonType.name }}
                </small>
              </td>
              <td style="width: 5%" class="px-2">
                <small>{{
                  ll.courseInfo.ENROLLMENT_CATEGORY_NAME.replace("судлах", "")
                }}</small>
              </td>
              <td class="px-1">
                <small>
                  {{
                    ll.classGroups.map((cg) => cg.classGroupFullName).join(",")
                  }}</small
                >
              </td>
              <td class="px-2">
                <span v-if="ll['unelgee2-' + program.STUDENT_GROUP_ID]">{{
                  ll["unelgee2-" + program.STUDENT_GROUP_ID].length
                }}</span>
              </td>
              <td class="px-2">
                <small>
                  {{
                    ll.byTeachers.map((tt) => tt.teacherDisplayName).join(",")
                  }}
                </small>
              </td>
              <td class="px-0">
                <v-row no-gutters>
                  <v-col
                    :key="'bodyy' + level"
                    v-for="level in [
                      'VIII',
                      'VII',
                      'VI',
                      'V',
                      'IV',
                      'III',
                      'II',
                      'I',
                    ]"
                    class="text-center"
                    style="border-right: 2px solid #dddddd"
                    :style="ll['I'] > 0 ? 'background:#C51162; color:#fff' : ''"
                    >{{
                      !ll[
                        "year" +
                          selectedHalfYear.yearId +
                          "UnelgeeScores-" +
                          program.STUDENT_GROUP_ID
                      ] ||
                      !ll[
                        "year" +
                          selectedHalfYear.yearId +
                          "UnelgeeScores-" +
                          program.STUDENT_GROUP_ID
                      ][level] ||
                      ll[
                        "year" +
                          selectedHalfYear.yearId +
                          "UnelgeeScores-" +
                          program.STUDENT_GROUP_ID
                      ][level] == 0
                        ? "-"
                        : ll[
                            "year" +
                              selectedHalfYear.yearId +
                              "UnelgeeScores-" +
                              program.STUDENT_GROUP_ID
                          ][level]
                    }}</v-col
                  >
                </v-row>
              </td>

              <td class="px-1">
                <span v-if="ll['mustExecution-' + program.STUDENT_GROUP_ID]">{{
                  ll["mustExecution-" + program.STUDENT_GROUP_ID].toFixed(0)
                }}</span>
              </td>
              <td class="px-1">
                <span v-if="ll['isExecution-' + program.STUDENT_GROUP_ID]">{{
                  ll["isExecution-" + program.STUDENT_GROUP_ID].toFixed(0)
                }}</span>
              </td>
              <td class="px-1">
                <span v-if="ll['finalExecution-' + program.STUDENT_GROUP_ID]"
                  >{{
                    ll["finalExecution-" + program.STUDENT_GROUP_ID].toFixed(0)
                  }}%</span
                >
              </td>
              <td>
                <v-btn
                  class="mx-4 text-capitalize my-1"
                  elevation="0"
                  style="background-color: #de477c; color: white"
                  x-small
                  @click="
                    selectedLesson = ll;
                    selectedProgram = program;
                    showUnelgee2Dialog = true;
                  "
                  >Дүн xараx</v-btn
                >
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>

      <v-dialog
        v-if="selectedLesson && selectedProgram"
        v-model="showUnelgee2Dialog"
        scrollable
        width="60%"
      >
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="10" md="10" lg="10">
                <h3>
                  {{ selectedLesson.courseInfo.COURSE_NAME }} -
                  <span class="blue--text">{{
                    selectedLesson.esisLessonType.name
                  }}</span>
                  -
                  {{ selectedLesson.courseInfo.ENROLLMENT_CATEGORY_NAME }}
                </h3>
              </v-col>
              <v-col cols="2" md="2" lg="2" class="text-end">
                <v-icon
                  large
                  color="#bbb"
                  @click="
                    selectedLesson = null;
                    showUnelgee2Dialog = !showUnelgee2Dialog;
                  "
                  >mdi-close-circle</v-icon
                >
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row class="font-weight-bold">
              <v-col> Сурагчийн нэр </v-col>
              <v-col class="blue--text"> Бүлэг </v-col>
              <v-col> Дүн </v-col>
              <v-col>
                <span> Огноо </span>
              </v-col>
              <v-col> Баталгаажуулсан </v-col>
            </v-row>
            <v-row
              @click="_print(scorr)"
              v-for="(scorr, sIndex) in selectedLesson[
                'unelgee2-' + selectedProgram.STUDENT_GROUP_ID
              ].filter(
                (scre) =>
                  Number(scre.STUDENT_GROUP_ID) ==
                  Number(selectedProgram.STUDENT_GROUP_ID)
              )"
              :key="sIndex + scorr.id"
            >
              <v-col>
                {{ sIndex + 1 }} .
                {{ scorr.FIRST_NAME + "." + scorr.LAST_NAME[1].toUpperCase() }}
              </v-col>
              <v-col class="blue--text">
                {{ scorr.STUDENT_GROUP_NAME }}
              </v-col>
              <v-col>
                {{ scorr.scoreFinal }}
              </v-col>
              <v-col>
                <span v-if="scorr.approved">
                  {{ scorr.approvedAt.toDate().toLocaleDateString() }}
                </span>
              </v-col>
              <v-col>
                {{ scorr.approvedByEmail }}
              </v-col>
              <!-- <v-col>
                <v-btn color="red" x-small @click="deleteUnelgeeScore(scorr)"
                  >DELETE SCORE</v-btn
                >
              </v-col> -->
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="px-16 mx-16">
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>
<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import XLSX from "xlsx";
Vue.use(VueSweetalert2);
const fb = require("@/firebaseConfig.js"); // eslint-disable-line

export default {
  data() {
    return {
      selectedAllGroups: null,
      selectedSubject: null,
      selectedLesson: null,
      showUnelgee2Dialog: false,
      lessonsAll: null,
      lessons: null,
      selectedHalfYear: null,
      preText: null,
      departments: null,
      selectedDepartment: null,
      selectedProgram: null,
      selectedClassGroup: null,
      students: null,
      halfYearDate: null,
    };
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),

    filteredLessonsBySubject() {
      var list = [];
      if (this.lessonsAll) {
        for (const ll of this.lessonsAll) {
          var found = list.find(
            (lll) => lll.SUBJECT_AREA_ID == ll.courseInfo.SUBJECT_AREA_ID
          );
          if (!found)
            list.push({
              SUBJECT_AREA_ID: ll.courseInfo.SUBJECT_AREA_ID,
              SUBJECT_AREA_NAME: ll.courseInfo.SUBJECT_AREA_NAME,
            });
        }
      }
      return list.sort((a, b) => a.SUBJECT_AREA_ID - b.SUBJECT_AREA_ID);
    },
    filteredLessons() {
      var list = [];
      if (this.lessons) {
        this.lessons.forEach((lesson) => {
          list.push(lesson);
        });
      }

      list.sort(
        (a, b) => a.courseInfo.PROGRAM_STAGE_ID - b.courseInfo.PROGRAM_STAGE_ID
      );
      list.forEach((item, idx) => {
        item.index = idx + 1;
      });
      return list;
    },
  },
  components: {},
  watch: {
    async selectedSubject(val) {
      console.log(val);
      // var list = [];
      // var query = this.userData.school.ref
      //   .collection("unelgee2-2023-scores")
      //   .doc(
      //     this.selectedHalfYear.yearId > 1
      //       ? lesson.id + "-" + this.selectedHalfYear.yearId
      //       : lesson.id
      //   )
      //   .collection("scores")
      //   .where("approved", "==", true);

      // await query.get().then((docs) => {
      //   console.log(docs.size);
      // });
    },
    selectedDepartment(val) {
      if (val) {
        console.log(val.classGroups);
        this.selectedAllGroups = [];
        var deps = this.departments.filter((dd) => dd.index > 9);
        for (const dep of deps) {
          this.selectedAllGroups.push(...dep.classGroups);
        }
        console.log("selectedGroups.length", this.selectedAllGroups);
        this.readAllLessons(val.classGroups);
        // this.readAllLessons(this.selectedAllGroups);
      }
    },
    selectedClassGroup(val) {
      if (val) {
        this.userData.school.ref
          .collection("students-" + this.userData.school.currentYear)
          .where("STUDENT_GROUP_ID", "==", val.STUDENT_GROUP_ID)
          .orderBy("FIRST_NAME", "asc")
          .get()
          .then(async (docs) => {
            this.students = [];
            var counter = 0;
            docs.docs.forEach((doc) => {
              let stud = doc.data();
              stud.id = doc.id;
              stud.ref = doc.ref;

              if (this.selectedHalfYear.yearId == 1) {
                if (
                  !stud.ACTION_DATE ||
                  new Date(stud.ACTION_DATE) <= this.halfYearDate
                ) {
                  if (!stud.moved) {
                    counter++;
                    stud.index = counter;
                    // console.log(stud);
                    this.students.push(stud);
                  }
                }
              } else {
                if (
                  !stud.ACTION_DATE ||
                  new Date(stud.ACTION_DATE) > this.halfYearDate
                ) {
                  if (!stud.moved) {
                    counter++;
                    stud.index = counter;
                    // console.log(stud);
                    this.students.push(stud);
                  }
                }
              }
              // if (!stud.moved) {
              //   counter++;
              //   stud.index = counter;
              //   // console.log(stud);
              //   this.students.push(stud);
              // }
            });
          });
      }
    },
  },
  created() {
    this.preText =
      this.userData.school.currentYear +
      "-" +
      Number(this.userData.school.currentYear + 1);
    this.selectedHalfYear = this.$store.state.currentSchoolHalfYear;

    this.halfYearDate = new Date(
      this.$store.state.calendarButez2[0].months[
        this.$store.state.calendarButez2[0].months.length - 1
      ].days[
        this.$store.state.calendarButez2[0].months[
          this.$store.state.calendarButez2[0].months.length - 1
        ].days.length - 1
      ].year +
        "-" +
        this.$store.state.calendarButez2[0].months[
          this.$store.state.calendarButez2[0].months.length - 1
        ].name +
        "-" +
        this.$store.state.calendarButez2[0].months[
          this.$store.state.calendarButez2[0].months.length - 1
        ].days[
          this.$store.state.calendarButez2[0].months[
            this.$store.state.calendarButez2[0].months.length - 1
          ].days.length - 1
        ].day
    );
    this.loading = true;
    this.userData.school.ref
      .collection("departments-" + this.userData.school.currentYear)
      .orderBy("index", "desc")
      .get()
      .then((docs) => {
        this.departments = [];
        docs.forEach(async (doc) => {
          let dep = doc.data();
          dep.ref = doc.ref;
          dep.id = doc.id;
          dep.classGroups = null;
          await dep.ref
            .collection("programs")
            .orderBy("STUDENT_GROUP_NAME", "asc")
            .get()
            .then((docs) => {
              dep.classGroups = [];
              docs.forEach((doc) => {
                let classGroup = doc.data();
                classGroup.ref = doc.ref;
                classGroup.id = doc.id;
                dep.classGroups.push(classGroup);
              });
            });

          this.departments.push(dep);
        });
        this.loading = false;
      });

    this.loading = true;
  },
  methods: {
    readAll() {
      var allGroups = [];
      var deps = this.departments.filter((dd) => dd.index > 10);
      for (const dep of deps) {
        allGroups.push(...dep.classGroups);
      }
      for (const gg of allGroups) {
        console.log(gg.STUDENT_GROUP_NAME);
      }
      this.readAllLessons(allGroups);
    },
    _download() {
      const wb = XLSX.utils.book_new();
      // this.excelLoading = true;
      var excelData = [];
      this.filteredLessonsBySubject.forEach((ss, iindex) => {
        var row = {};
        row["No."] = iindex + 1;
        row["Xичээл"] = ss.SUBJECT_AREA_NAME;

        ["VIII", "VII", "VI", "V", "IV", "III", "II", "I"].forEach((level) => {
          row[level] = this.getAllScoresBySubject(level, ss.SUBJECT_AREA_ID);
        });
        row["Гүйцэтгэл"] = this.getOverAllExecution(
          ss.SUBJECT_AREA_ID,
          "finalExecution"
        ).toFixed(0);
        excelData.push(row);
      });

      var row2 = {};
      row2["No."] = "";
      row2["Xичээл"] = "";

      ["VIII", "VII", "VI", "V", "IV", "III", "II", "I"].forEach((level) => {
        row2[level] = this.getColumnScores(level);
      });
      row2["Гүйцэтгэл"] = this.getOverAllFinalExecution().toFixed(0);

      excelData.push(row2);
      const data = XLSX.utils.json_to_sheet(excelData);
      XLSX.utils.book_append_sheet(wb, data, "negtgel");
      // this.excelLoading = false;
      XLSX.writeFile(wb, "school_negtgel.xlsx");
    },
    async deleteUnelgeeScore(score) {
      var batch = fb.db.batch();
      console.log(score);
      var selectedProgram = this.selectedDepartment.classGroups.find((cg) =>
        cg.students.find(
          (stud) => Number(stud.PERSON_ID) == Number(score.PERSON_ID)
        )
      );

      var tmp = {
        approvedNumber: 0,
        rejectedNumber: 0,
        inProgressNumber: 0,
        savedNumber: 0,
        scoreAverage: 0,
      };

      var allDone = true;

      var divideBy = 0;
      this.selectedLesson[
        "unelgee2-" + selectedProgram.STUDENT_GROUP_ID
      ].forEach((unelgee) => {
        if (unelgee.scoreRef.path != score.scoreRef.path) {
          if (unelgee.approved == true) {
            tmp.approvedNumber++;

            if (
              this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
              this.selectedLesson.courseInfo.GRADING_SCHEME_ID == 14
            ) {
              if (unelgee.score > 0) {
                tmp.scoreAverage = tmp.scoreAverage + 100;
              } else {
                tmp.scoreAverage = tmp.scoreAverage + unelgee.score;
              }
            } else {
              //baga angias deesh dungeer toozdog bol
              tmp.scoreAverage = tmp.scoreAverage + unelgee.score;
            }
            divideBy++;
          } else if (unelgee.approved == false) {
            tmp.rejectedNumber++;
            allDone = false;
          } else if (unelgee.sentToManager == true) {
            tmp.inProgressNumber++;
            allDone = false;
          } else {
            tmp.scoreAverage++;
            allDone = false;
          }
        }
      });

      tmp.scoreAverage = Number((tmp.scoreAverage / divideBy).toFixed(1));

      tmp["allUnelgee2ScoresConfirmed-" + this.selectedHalfYear.yearId] =
        allDone;
      if (allDone)
        tmp["allUnelgee2ScoresConfirmedAt-" + this.selectedHalfYear.yearId] =
          new Date();

      var programAnalytics = {};
      programAnalytics[
        "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
      ] = {};
      programAnalytics["year" + this.selectedHalfYear.yearId + "UnelgeeScores"][
        this.selectedLesson.id
      ] = tmp;

      var teacherAnalytics = {};
      teacherAnalytics[
        "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
      ] = {};
      teacherAnalytics["year" + this.selectedHalfYear.yearId + "UnelgeeScores"][
        this.selectedLesson.id + "-" + selectedProgram.STUDENT_GROUP_ID
      ] = tmp;

      var lessonAnalytics = {};
      lessonAnalytics[
        "year" +
          this.selectedHalfYear.yearId +
          "UnelgeeScores-" +
          selectedProgram.STUDENT_GROUP_ID
      ] = tmp;

      console.log(programAnalytics, teacherAnalytics, lessonAnalytics);

      batch.set(selectedProgram.ref, programAnalytics, { merge: true });
      batch.set(
        this.userData.school.ref
          .collection(
            "unelgee2-" + this.userData.school.currentYear + "-teachers"
          )
          .doc(String(this.selectedLesson.byTeachers[0].teacherId)),
        teacherAnalytics,
        { merge: true }
      );
      batch.set(this.selectedLesson.ref, lessonAnalytics, { merge: true });

      batch.delete(score.ref);

      // batch.commit().then(() => { //25109
      //   console.log("score deleted");
      // });
    },
    findIsExecution(classUnelgee2, lesson) {
      var totalScore = 0;
      classUnelgee2.forEach((score) => {
        if (typeof score.scoreFinal == "number") {
          if (
            lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
            lesson.courseInfo.GRADING_SCHEME_ID == 14
          ) {
            if (score.scoreFinal <= 1) {
              totalScore = totalScore + score.scoreFinal * 100;
            } else {
              totalScore = totalScore + score.scoreFinal;
            }
          } else {
            totalScore = totalScore + score.scoreFinal;
          }
        }
      });
      return totalScore;
    },
    getOverAllFinalExecution() {
      // year1UnelgeeScores
      var sum = 0;
      for (const ss of this.filteredLessonsBySubject) {
        //
        sum =
          sum + this.getOverAllExecution(ss.SUBJECT_AREA_ID, "finalExecution");
      }
      if (this.filteredLessonsBySubject.length > 0) {
        return sum / this.filteredLessonsBySubject.length;
      } else return 0;
    },
    getOverAllExecution(subjectId, keywordd) {
      var list = [];
      var sum = 0;
      if (this.lessonsAll) {
        for (const ll of this.lessonsAll) {
          for (var classGroup of ll.classGroups) {
            if (ll.courseInfo.SUBJECT_AREA_ID == subjectId) {
              if (ll[keywordd + "-" + classGroup.STUDENT_GROUP_ID]) {
                list.push(ll);
                sum = sum + ll[keywordd + "-" + classGroup.STUDENT_GROUP_ID];
              }
            }
          }
        }
      }
      if (list.length && sum) return sum / list.length;
      return 0;
    },
    getAllScoresBySubject(level, subjectId) {
      var sum = 0;
      if (this.lessonsAll) {
        for (const ll of this.lessonsAll) {
          for (var classGroup of ll.classGroups) {
            if (ll.courseInfo.SUBJECT_AREA_ID == subjectId) {
              if (
                ll[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    classGroup.STUDENT_GROUP_ID
                ] &&
                ll[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    classGroup.STUDENT_GROUP_ID
                ][level]
              ) {
                sum =
                  sum +
                  ll[
                    "year" +
                      this.selectedHalfYear.yearId +
                      "UnelgeeScores-" +
                      classGroup.STUDENT_GROUP_ID
                  ][level];
              }
            }
          }
        }
      }

      return sum;
    },
    getColumnScores(level) {
      var sum = 0;
      if (this.lessonsAll) {
        for (const ll of this.lessonsAll) {
          for (var classGroup of ll.classGroups) {
            if (
              ll[
                "year" +
                  this.selectedHalfYear.yearId +
                  "UnelgeeScores-" +
                  classGroup.STUDENT_GROUP_ID
              ] &&
              ll[
                "year" +
                  this.selectedHalfYear.yearId +
                  "UnelgeeScores-" +
                  classGroup.STUDENT_GROUP_ID
              ][level]
            ) {
              sum =
                sum +
                ll[
                  "year" +
                    this.selectedHalfYear.yearId +
                    "UnelgeeScores-" +
                    classGroup.STUDENT_GROUP_ID
                ][level];
            }
          }
        }
      }

      return sum;
    },
    _getAverage(lesson, classGroup) {
      return lesson[
        "year" +
          this.selectedHalfYear.yearId +
          "UnelgeeScores-" +
          classGroup.STUDENT_GROUP_ID
      ]["scoreAverage"];
    },
    _getNumberOfStudentsOfUnelgee2(lesson, classGroup) {
      return lesson[
        "year" +
          this.selectedHalfYear.yearId +
          "UnelgeeScores-" +
          classGroup.STUDENT_GROUP_ID
      ]
        ? lesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              classGroup.STUDENT_GROUP_ID
          ].approvedNumber
        : null;
    },
    async getUnelgeeData(lesson, classGroup, classGroupStudents) {
      var list = [];
      var lessonRef = this.userData.school.ref
        .collection("unelgee2-" + this.userData.school.currentYear + "-scores")
        .doc(
          this.selectedHalfYear.yearId > 1
            ? lesson.id + "-" + this.selectedHalfYear.yearId
            : lesson.id
        );
      // console.log(lessonRef.path, "lessonRef")
      var query = lessonRef.collection("scores").where("approved", "==", true);

      await query.get().then((docs) => {
        // console.log(docs.size);
        docs.forEach((doc) => {
          let studentScore = doc.data();
          studentScore.id = doc.id;
          studentScore.ref = doc.ref;
          if (
            lesson.courseInfo.GRADING_SCHEME_ID == 13 ||
            lesson.courseInfo.GRADING_SCHEME_ID == 14
          ) {
            if (studentScore.scoreFinal <= 1) {
              studentScore.scoreFinal = studentScore.scoreFinal * 100;
            }
          }

          if (
            String(studentScore.STUDENT_GROUP_ID) ==
            String(classGroup.STUDENT_GROUP_ID)
          ) {
            list.push(studentScore);
          }
        });

        var unelgee2 = list.filter(
          (score) =>
            classGroupStudents.find(
              (student) => String(student.PERSON_ID) == String(score.PERSON_ID)
            ) != undefined
        );
        // console.log(
        //   this.countDuplicates(
        //     unelgee2.map((ss) => ss.scoreLevelString),
        lesson["unelgee2-" + classGroup.STUDENT_GROUP_ID] = unelgee2;

        lesson["isExecution-" + classGroup.STUDENT_GROUP_ID] =
          this.findIsExecution(unelgee2, lesson);
        lesson["mustExecution-" + classGroup.STUDENT_GROUP_ID] =
          lesson["unelgee2-" + classGroup.STUDENT_GROUP_ID].filter(
            (ss) =>
              Number(ss.STUDENT_GROUP_ID) ==
                Number(classGroup.STUDENT_GROUP_ID) && ss.approved
          ).length * 100;

        if (
          lesson["mustExecution-" + classGroup.STUDENT_GROUP_ID] &&
          lesson["isExecution-" + classGroup.STUDENT_GROUP_ID]
        )
          lesson["finalExecution-" + classGroup.STUDENT_GROUP_ID] =
            (lesson["isExecution-" + classGroup.STUDENT_GROUP_ID] /
              lesson["mustExecution-" + classGroup.STUDENT_GROUP_ID]) *
            100;
        else lesson["finalExecution-" + classGroup.STUDENT_GROUP_ID] = 0;

        lesson[
          "year" +
            this.selectedHalfYear.yearId +
            "UnelgeeScores-" +
            classGroup.STUDENT_GROUP_ID
        ] = this.countDuplicates(
          unelgee2.map((ss) => ss.scoreLevelString),
          "aaa"
        );
        this.$forceUpdate();
        // console.log(lesson);
        // console.log("scores", list);
        // console.log(
        //   lesson["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
        // );
      });

      return list;
    },
    countDuplicates(arr) {
      const counts = {};
      arr.forEach((item) => {
        counts[item] = (counts[item] || 0) + 1;
      });
      return counts;
    },
    isThisSemesterLesson(lesson) {
      if (lesson) {
        if (lesson.startEnd) {
          if (this.selectedHalfYear.yearId > 1) {
            if (
              (lesson.startEnd["semester-2"] &&
                lesson.startEnd["semester-2"].available) ||
              (lesson.startEnd["semester-3"] &&
                lesson.startEnd["semester-3"].available)
            ) {
              lesson.isNotThisSemester = false;
            } else {
              lesson.isNotThisSemester = true;
            }
          } else {
            if (
              lesson.startEnd["semester-1"] &&
              lesson.startEnd["semester-1"].available
            ) {
              lesson.isNotThisSemester = false;
            } else {
              lesson.isNotThisSemester = true;
            }
          }
        } else {
          lesson.isNotThisSemester = false;
        }
      }
    },
    readAllLessons(classGroups) {
      // console.log(this.departments);
      this.lessonsAll = [];
      for (const cg of classGroups) {
        // console.log(cg.STUDENT_GROUP_NAME);
        this.userData.school.ref
          .collection("students-" + this.userData.school.currentYear)
          .where("STUDENT_GROUP_ID", "==", cg.STUDENT_GROUP_ID)
          .orderBy("FIRST_NAME", "asc")
          .get()
          .then(async (docs) => {
            var students = [];
            var counter = 0;
            docs.docs.forEach((doc) => {
              let stud = doc.data();
              stud.id = doc.id;
              stud.ref = doc.ref;

              if (this.selectedHalfYear.yearId == 1) {
                if (
                  !stud.ACTION_DATE ||
                  new Date(stud.ACTION_DATE) <= this.halfYearDate
                ) {
                  if (!stud.moved) {
                    counter++;
                    stud.index = counter;
                    students.push(stud);
                  }
                }
              } else {
                if (
                  !stud.ACTION_DATE ||
                  new Date(stud.ACTION_DATE) > this.halfYearDate
                ) {
                  if (!stud.moved) {
                    counter++;
                    stud.index = counter;
                    students.push(stud);
                  }
                }
              }

              // if (!stud.moved) {
              //   counter++;
              //   stud.index = counter;
              //   students.push(stud);
              // }
            });
            cg.students = students;
            this.readLessons(cg, students);
          });
      }
    },

    readLessons(classGroup, classGroupStudents) {
      // console.log(this.userData.school.ref, classGroup);
      this.userData.school.ref
        .collection("lessons-" + this.userData.school.currentYear)
        .where("classGroupRefs", "array-contains", classGroup.ref)
        .orderBy("courseInfo.COURSE_NAME", "asc")
        .onSnapshot((docs) => {
          this.lessons = [];
          docs.forEach(async (doc) => {
            let lesson = doc.data();
            lesson.ref = doc.ref;
            lesson.id = doc.id;
            if (!lesson.deleted) {
              this.isThisSemesterLesson(lesson);
              if (!lesson.isNotThisSemester) {
                var found = this.lessonsAll.find((ll) => ll.id == lesson.id);
                var hasFound = false;
                if (!found) {
                  found = lesson;
                  found[
                    "year" +
                      this.selectedHalfYear.yearId +
                      "UnelgeeScores-" +
                      classGroup.STUDENT_GROUP_ID
                  ] = {};
                } else {
                  hasFound = true;
                }

                this.getUnelgeeData(found, classGroup, classGroupStudents);
                this.lessons.push(found);
                if (!hasFound) this.lessonsAll.push(found);
              }
            }
          });
          this.lessonsAll
            .sort(
              (a, b) =>
                a.courseInfo.SUBJECT_AREA_ID - b.courseInfo.SUBJECT_AREA_ID
            )
            .sort(
              (a, b) =>
                b.courseInfo.ENROLLMENT_CATEGORY.length -
                a.courseInfo.ENROLLMENT_CATEGORY.length
            )
            .sort((a, b) => {
              if (
                a.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE" &&
                b.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE"
              ) {
                if (
                  Number(a.courseInfo.COURSE_CLASSIFICATION) <
                  Number(b.courseInfo.COURSE_CLASSIFICATION)
                ) {
                  return -1;
                } else if (
                  Number(a.courseInfo.COURSE_CLASSIFICATION) >
                  Number(b.courseInfo.COURSE_CLASSIFICATION)
                ) {
                  return 1;
                } else {
                  if (
                    a.courseInfo.COURSE_NAME.localeCompare(
                      b.courseInfo.COURSE_NAME
                    ) > 0
                  ) {
                    return 1;
                  } else if (
                    a.courseInfo.COURSE_NAME.localeCompare(
                      b.courseInfo.COURSE_NAME
                    ) < 0
                  ) {
                    return -1;
                  } else {
                    return 0;
                  }
                }
              } else if (
                a.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE" ||
                b.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE"
              ) {
                if (a.courseInfo.ENROLLMENT_CATEGORY == "MANDATORY") {
                  return -1;
                } else {
                  return 1;
                }
              }
              return 0;
            });
          this.loading = false;
        });
    },
  },
};
</script>
<style>
.scoreTables td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}

.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}

.bborder td {
  border-right: 1px solid #bbb !important;
}
.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}
.bborder .borderCell:hover {
  background-color: red;
}

.attendanceTable td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}

.attendanceTable th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
</style>
