var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"px-10 py-10"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"lg":"8","md":"8","sm":"8","cols":"8"}},[_c('h5',{staticClass:"font-weight-bold text-h2 text-typo mb-0"},[_vm._v(" Үнэлгээ 2 -ийн дүн шинжилгээ ")]),_c('p',{staticClass:"text-body mb-0"},[_vm._v(" Анги, эсвэл бүлгээ сонгож дүн шинжилгээ харах боломжтой. ")])]),_c('v-col',{staticStyle:{"background-color":"yellow"},attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":[
              {
                yearId: 1,
                name: _vm.preText + ' xичээлийн жил, 1-р xагас жил',
              },
              {
                yearId: 2,
                name: _vm.preText + ' xичээлийн жил, 2-р xагас жил',
              },
            ],"label":"Улирал","return-object":"","item-text":"name","item-value":"yearId"},model:{value:(_vm.selectedHalfYear),callback:function ($$v) {_vm.selectedHalfYear=$$v},expression:"selectedHalfYear"}})],1)],1),_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[(_vm.departments)?_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.departments,"item-value":"id","item-text":"name","return-object":"","label":"Анги сонгох","clearable":""},model:{value:(_vm.selectedDepartment),callback:function ($$v) {_vm.selectedDepartment=$$v},expression:"selectedDepartment"}})],1):_vm._e(),(_vm.selectedDepartment && _vm.selectedDepartment.classGroups)?_c('v-col',{staticClass:"px-4",attrs:{"cols":"4"}},[_c('v-select',{attrs:{"disabled":"","items":_vm.selectedDepartment.classGroups,"item-value":"id","item-text":"STUDENT_GROUP_NAME","return-object":"","label":"Бүлэг сонгох","clearable":""},model:{value:(_vm.selectedClassGroup),callback:function ($$v) {_vm.selectedClassGroup=$$v},expression:"selectedClassGroup"}})],1):_vm._e()],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"my-3",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"mt-4 ml-3",attrs:{"color":"green","dark":""},on:{"click":_vm._download}},[_vm._v(" Excel татаx ")])],1)],1),(
          _vm.filteredLessonsBySubject &&
          _vm.selectedDepartment &&
          _vm.lessonsAll &&
          _vm.lessonsAll.length > 0
        )?_c('v-simple-table',{staticClass:"attendanceTable"},[_c('thead',[_c('tr',[_c('th',{staticClass:"blue--text pl-1"},[_vm._v("No")]),_c('th',{staticClass:"pl-1"},[_vm._v("Xичээлүүд")]),_c('th',{staticClass:"pl-1"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center font-weight-normal blue--text",staticStyle:{"border-bottom":"2px solid #dddddd"},attrs:{"cols":"12"}},[_vm._v("Түвшин")]),_vm._l(([
                    'VIII',
                    'VII',
                    'VI',
                    'V',
                    'IV',
                    'III',
                    'II',
                    'I',
                  ]),function(level){return _c('v-col',{key:'head' + level,staticClass:"text-center blue--text",staticStyle:{"border-right":"2px solid #dddddd","background-color":"#dddddd"}},[_vm._v(_vm._s(level))])})],2)],1),_c('th',{staticClass:"text-center",staticStyle:{"background-color":"#fbff04"}},[_vm._v("ГҮ")])])]),_c('tbody',[_vm._l((_vm.filteredLessonsBySubject),function(ss,ssIndex){return _c('tr',{key:ssIndex + 'ttt'},[_c('td',{staticClass:"px-1",staticStyle:{"width":"2%"}},[_vm._v(_vm._s(ssIndex + 1))]),_c('td',{staticClass:"px-2 py-1",staticStyle:{"width":"30%"}},[_vm._v(" "+_vm._s(ss.SUBJECT_AREA_NAME)+" ")]),_c('td',{staticClass:"px-0"},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l(([
                    'VIII',
                    'VII',
                    'VI',
                    'V',
                    'IV',
                    'III',
                    'II',
                    'I',
                  ]),function(level){return _c('v-col',{key:'bodytd' + level,staticClass:"text-center",staticStyle:{"border-right":"2px solid #dddddd"}},[_vm._v(_vm._s(_vm.getAllScoresBySubject(level, ss.SUBJECT_AREA_ID)))])}),1)],1),_c('td',{staticClass:"text-center",staticStyle:{"width":"5%","background-color":"#fbff04"},on:{"click":function($event){return _vm._dddd(ss.SUBJECT_AREA_ID)}}},[_vm._v(" "+_vm._s(_vm.getOverAllExecution( ss.SUBJECT_AREA_ID, "finalExecution" ).toFixed(0))+"% ")])])}),_c('tr',{staticStyle:{"background-color":"#dddddd","font-weight":"bold","color":"red"}},[_c('td',{attrs:{"colspan":"2"}}),_c('td',[_c('v-row',{attrs:{"no-gutters":""}},_vm._l(([
                    'VIII',
                    'VII',
                    'VI',
                    'V',
                    'IV',
                    'III',
                    'II',
                    'I',
                  ]),function(level){return _c('v-col',{key:'bodytd' + level,staticClass:"text-center",staticStyle:{"border-right":"2px solid #dddddd"}},[_vm._v(_vm._s(_vm.getColumnScores(level)))])}),1)],1),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.getOverAllFinalExecution().toFixed(0))+"% ")])])],2)]):_vm._e(),(
          _vm.loading == false &&
          _vm.filteredLessons &&
          _vm.filteredLessons.length > 0 &&
          _vm.selectedClassGroup
        )?_c('v-data-table',{staticClass:"scoreTables",attrs:{"items":_vm.filteredLessons,"hide-default-footer":"","hide-default-header":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"text-center",staticStyle:{"width":"1%"}},[_vm._v(" "+_vm._s(props.index + 1)+" ")]),_c('td',{staticClass:"pl-1",staticStyle:{"width":"30%"}},[_vm._v(" "+_vm._s(props.item.courseInfo.COURSE_NAME)+" ")]),_c('td',[_c('span',{staticClass:"blue--text"},[_vm._v(_vm._s(props.item.id))])]),_c('td',{staticStyle:{"width":"5%"}},[(props.item.esisLessonType.esisLessonTypeId == 2)?_c('span',{staticClass:"blue--text"},[_vm._v(" "+_vm._s(props.item.esisLessonType.name)+" ")]):(props.item.esisLessonType.esisLessonTypeId == 3)?_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(props.item.esisLessonType.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(props.item.esisLessonType.name)+" ")])]),_c('td',{staticClass:"pl-1"},[_vm._v(" "+_vm._s(_vm._getNumberOfStudentsOfUnelgee2(props.item, _vm.selectedClassGroup))+" ")]),_c('td',{staticClass:"px-0"},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l(([
                    'VIII',
                    'VII',
                    'VI',
                    'V',
                    'IV',
                    'III',
                    'II',
                    'I',
                  ]),function(level){return _c('v-col',{key:'head' + level,staticClass:"text-center",staticStyle:{"border-right":"2px solid #dddddd"},style:(props.item['I'] > 0
                      ? 'background:#C51162; color:#fff'
                      : '')},[_vm._v(_vm._s(!props.item[ "year" + _vm.selectedHalfYear.yearId + "UnelgeeScores-" + _vm.selectedClassGroup.STUDENT_GROUP_ID ] || !props.item[ "year" + _vm.selectedHalfYear.yearId + "UnelgeeScores-" + _vm.selectedClassGroup.STUDENT_GROUP_ID ][level] || props.item[ "year" + _vm.selectedHalfYear.yearId + "UnelgeeScores-" + _vm.selectedClassGroup.STUDENT_GROUP_ID ][level] == 0 ? "-" : props.item[ "year" + _vm.selectedHalfYear.yearId + "UnelgeeScores-" + _vm.selectedClassGroup.STUDENT_GROUP_ID ][level])+" ")])}),1)],1),_c('td',[_vm._v(" "+_vm._s(_vm._getAverage(props.item, _vm.selectedClassGroup))+" ")]),_c('td',[_c('v-btn',{on:{"click":function($event){return _vm.getUnelgeeData(props.item, _vm.selectedClassGroup)}}},[_vm._v("lkdfjsdf")])],1)])]}}],null,false,981952792)},[_c('template',{slot:"header"},[_c('tr',{staticStyle:{"font-size":"13px"}},[_c('th',{staticClass:"text-start font-weight-normal px-1"},[_vm._v("No")]),_c('th',{staticClass:"text-start font-weight-normal pl-1"},[_vm._v("Хичээл")]),_c('th',{staticClass:"pl-0"},[_vm._v("T1")]),_c('th',{staticClass:"pl-0"},[_vm._v("T2")]),_c('th',{staticClass:"pl-0"},[_vm._v("CT")]),_c('th',{staticClass:"pl-0",staticStyle:{"width":"50%"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center font-weight-normal",staticStyle:{"border-bottom":"2px solid #dddddd"},attrs:{"cols":"12"}},[_vm._v("Түвшин")]),_vm._l(([
                    'VIII',
                    'VII',
                    'VI',
                    'V',
                    'IV',
                    'III',
                    'II',
                    'I',
                  ]),function(level){return _c('v-col',{key:'heady_overall' + level,staticClass:"text-center font-weight-normal",staticStyle:{"border-right":"2px solid #dddddd"}},[_vm._v(_vm._s(level))])})],2)],1),_c('th',{staticClass:"pl-0"},[_vm._v("T1")]),_c('th',{staticClass:"pl-0"},[_vm._v("T2")])])])],2):(_vm.loading == true)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"red"}}):_vm._e()],1),(_vm.selectedAllGroups && _vm.lessonsAll && _vm.lessonsAll.length > 0)?_c('v-card',{staticClass:"pb-10"},[_c('h2',{staticClass:"text-typo px-10"},[_vm._v("Дэлгэрэнгүй мэдээлэл")]),_vm._l((_vm.selectedAllGroups),function(program,pindex){return _c('v-simple-table',{key:'program-' + pindex,staticClass:"attendanceTable px-10 mt-10"},[_c('thead',[_c('h2',{staticClass:"blue--text"},[_vm._v(_vm._s(program.STUDENT_GROUP_NAME))]),_c('tr',{staticStyle:{"font-size":"13px"}},[_c('th',{staticClass:"text-start font-weight-normal px-1"},[_vm._v("No")]),_c('th',{staticClass:"text-start font-weight-normal pl-1"},[_vm._v("Хичээл")]),_c('th',{staticClass:"px-2"},[_vm._v("Төрөл")]),_c('th',{staticClass:"px-2"},[_vm._v("Xэлбэр")]),_c('th',{staticClass:"px-2"},[_vm._v("Анги")]),_c('th',{staticClass:"px-2"},[_vm._v("CT")]),_c('th',{staticClass:"px-2"},[_vm._v("Багш")]),_c('th',{staticClass:"px-2",staticStyle:{"width":"50%","background-color":"#dddddd"}},[_c('v-row',{staticClass:"font-weight-bold",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center font-weight-normal",staticStyle:{"border-bottom":"2px solid #bbb"},attrs:{"cols":"12"}},[_vm._v("Түвшин")]),_vm._l(([
                    'VIII',
                    'VII',
                    'VI',
                    'V',
                    'IV',
                    'III',
                    'II',
                    'I',
                  ]),function(level){return _c('v-col',{key:'heady' + level,staticClass:"text-center font-weight-normal blue--text",staticStyle:{"border-right":"2px solid #dddddd"}},[_vm._v(_vm._s(level))])})],2)],1),_c('th',{staticClass:"text-center"},[_vm._v("Аваx оноо")]),_c('th',{staticClass:"text-center"},[_vm._v("Авсан оноо")]),_c('th',{staticClass:"text-center"},[_vm._v("ГҮ")]),_c('th',{staticClass:"text-center"},[_vm._v("Үйлдэл")])])]),_c('tbody',_vm._l((_vm.lessonsAll.filter((lsn) =>
              lsn.classGroups.find(
                (cg) =>
                  Number(cg.STUDENT_GROUP_ID) ==
                  Number(program.STUDENT_GROUP_ID)
              )
            )),function(ll,llIndex){return _c('tr',{key:ll.id + llIndex},[_c('td',{staticClass:"px-2"},[_vm._v(_vm._s(llIndex + 1))]),_c('td',{staticClass:"px-2",staticStyle:{"width":"35%"}},[_vm._v(" "+_vm._s(ll.courseInfo.COURSE_NAME)+" ")]),_c('td',{staticClass:"px-2",staticStyle:{"width":"5%"}},[(ll.esisLessonType.esisLessonTypeId == 2)?_c('small',{staticClass:"blue--text"},[_vm._v(" "+_vm._s(ll.esisLessonType.name)+" ")]):(ll.esisLessonType.esisLessonTypeId == 3)?_c('small',{staticClass:"red--text"},[_vm._v(" "+_vm._s(ll.esisLessonType.name)+" ")]):_c('small',[_vm._v(" "+_vm._s(ll.esisLessonType.name)+" ")])]),_c('td',{staticClass:"px-2",staticStyle:{"width":"5%"}},[_c('small',[_vm._v(_vm._s(ll.courseInfo.ENROLLMENT_CATEGORY_NAME.replace("судлах", "")))])]),_c('td',{staticClass:"px-1"},[_c('small',[_vm._v(" "+_vm._s(ll.classGroups.map((cg) => cg.classGroupFullName).join(",")))])]),_c('td',{staticClass:"px-2"},[(ll['unelgee2-' + program.STUDENT_GROUP_ID])?_c('span',[_vm._v(_vm._s(ll["unelgee2-" + program.STUDENT_GROUP_ID].length))]):_vm._e()]),_c('td',{staticClass:"px-2"},[_c('small',[_vm._v(" "+_vm._s(ll.byTeachers.map((tt) => tt.teacherDisplayName).join(","))+" ")])]),_c('td',{staticClass:"px-0"},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l(([
                    'VIII',
                    'VII',
                    'VI',
                    'V',
                    'IV',
                    'III',
                    'II',
                    'I',
                  ]),function(level){return _c('v-col',{key:'bodyy' + level,staticClass:"text-center",staticStyle:{"border-right":"2px solid #dddddd"},style:(ll['I'] > 0 ? 'background:#C51162; color:#fff' : '')},[_vm._v(_vm._s(!ll[ "year" + _vm.selectedHalfYear.yearId + "UnelgeeScores-" + program.STUDENT_GROUP_ID ] || !ll[ "year" + _vm.selectedHalfYear.yearId + "UnelgeeScores-" + program.STUDENT_GROUP_ID ][level] || ll[ "year" + _vm.selectedHalfYear.yearId + "UnelgeeScores-" + program.STUDENT_GROUP_ID ][level] == 0 ? "-" : ll[ "year" + _vm.selectedHalfYear.yearId + "UnelgeeScores-" + program.STUDENT_GROUP_ID ][level]))])}),1)],1),_c('td',{staticClass:"px-1"},[(ll['mustExecution-' + program.STUDENT_GROUP_ID])?_c('span',[_vm._v(_vm._s(ll["mustExecution-" + program.STUDENT_GROUP_ID].toFixed(0)))]):_vm._e()]),_c('td',{staticClass:"px-1"},[(ll['isExecution-' + program.STUDENT_GROUP_ID])?_c('span',[_vm._v(_vm._s(ll["isExecution-" + program.STUDENT_GROUP_ID].toFixed(0)))]):_vm._e()]),_c('td',{staticClass:"px-1"},[(ll['finalExecution-' + program.STUDENT_GROUP_ID])?_c('span',[_vm._v(_vm._s(ll["finalExecution-" + program.STUDENT_GROUP_ID].toFixed(0))+"%")]):_vm._e()]),_c('td',[_c('v-btn',{staticClass:"mx-4 text-capitalize my-1",staticStyle:{"background-color":"#de477c","color":"white"},attrs:{"elevation":"0","x-small":""},on:{"click":function($event){_vm.selectedLesson = ll;
                  _vm.selectedProgram = program;
                  _vm.showUnelgee2Dialog = true;}}},[_vm._v("Дүн xараx")])],1)])}),0)])})],2):_vm._e(),(_vm.selectedLesson && _vm.selectedProgram)?_c('v-dialog',{attrs:{"scrollable":"","width":"60%"},model:{value:(_vm.showUnelgee2Dialog),callback:function ($$v) {_vm.showUnelgee2Dialog=$$v},expression:"showUnelgee2Dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"10","md":"10","lg":"10"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.selectedLesson.courseInfo.COURSE_NAME)+" - "),_c('span',{staticClass:"blue--text"},[_vm._v(_vm._s(_vm.selectedLesson.esisLessonType.name))]),_vm._v(" - "+_vm._s(_vm.selectedLesson.courseInfo.ENROLLMENT_CATEGORY_NAME)+" ")])]),_c('v-col',{staticClass:"text-end",attrs:{"cols":"2","md":"2","lg":"2"}},[_c('v-icon',{attrs:{"large":"","color":"#bbb"},on:{"click":function($event){_vm.selectedLesson = null;
                  _vm.showUnelgee2Dialog = !_vm.showUnelgee2Dialog;}}},[_vm._v("mdi-close-circle")])],1)],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',{staticClass:"font-weight-bold"},[_c('v-col',[_vm._v(" Сурагчийн нэр ")]),_c('v-col',{staticClass:"blue--text"},[_vm._v(" Бүлэг ")]),_c('v-col',[_vm._v(" Дүн ")]),_c('v-col',[_c('span',[_vm._v(" Огноо ")])]),_c('v-col',[_vm._v(" Баталгаажуулсан ")])],1),_vm._l((_vm.selectedLesson[
              'unelgee2-' + _vm.selectedProgram.STUDENT_GROUP_ID
            ].filter(
              (scre) =>
                Number(scre.STUDENT_GROUP_ID) ==
                Number(_vm.selectedProgram.STUDENT_GROUP_ID)
            )),function(scorr,sIndex){return _c('v-row',{key:sIndex + scorr.id,on:{"click":function($event){return _vm._print(scorr)}}},[_c('v-col',[_vm._v(" "+_vm._s(sIndex + 1)+" . "+_vm._s(scorr.FIRST_NAME + "." + scorr.LAST_NAME[1].toUpperCase())+" ")]),_c('v-col',{staticClass:"blue--text"},[_vm._v(" "+_vm._s(scorr.STUDENT_GROUP_NAME)+" ")]),_c('v-col',[_vm._v(" "+_vm._s(scorr.scoreFinal)+" ")]),_c('v-col',[(scorr.approved)?_c('span',[_vm._v(" "+_vm._s(scorr.approvedAt.toDate().toLocaleDateString())+" ")]):_vm._e()]),_c('v-col',[_vm._v(" "+_vm._s(scorr.approvedByEmail)+" ")])],1)})],2),_c('v-divider'),_c('v-card-actions',{staticClass:"px-16 mx-16"},[_c('v-spacer')],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }